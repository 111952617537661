import { PartnerAdSetPayType } from 'lib/types/partner-ad-set'
import { IPerformanceAdSet, IPerformanceAdSetModel } from 'lib/types/partner-ad-set/performance'
import { IPerformanceAdSetPayloadTo } from 'lib/types/payloads/partner-ad-set/performance'

export const performanceAdSetToModelAdapter = (payload: IPerformanceAdSet): IPerformanceAdSetModel => {
  return {
    title: payload.title,
    description: payload.description,
    externalId: payload.external_id,
    platform: payload.platform,
    start: payload.start,
    end: payload.end,
    erid: payload.erid,
    payType: PartnerAdSetPayType.IMPRESSIONS,
    bidCap: payload.bid_cap,
    bidCpa: payload.bid_cpa,
    format: payload.format.id,
    impressions: payload.impressions,
    budget: payload.budget,
    excludeStreamers: payload.targeting.exclude_streamers,
    streamers: payload.targeting.streamers,
    languages: payload.targeting.broadcaster_languages,
    excludeLanguages: payload.targeting.exclude_languages,
    countries: payload.targeting.countries,
    excludeCountries: payload.targeting.exclude_countries,
    countriesAuditory: payload.targeting.countries_auditory,
    excludeCountriesAuditory: payload.targeting.exclude_countries_auditory,
    devicesAuditory: payload.targeting.devices_auditory,
    excludeDevicesAuditory: payload.targeting.exclude_devices_auditory,
    tags: payload.targeting.tags,
    excludeTags: payload.targeting.exclude_tags,
    chatbotProductLink: payload.product_url,
    chatbotMobileProductLink: payload.mobile_product_url,
    chatbotText: payload.chatbot_text,
    unit: payload.attachments?.unit || null,
    enabled: payload.visible,
    timezone: payload.time_zone,
    targetCtr: payload.target_ctr,
    targetCpa: payload.target_cpa,
    productUrlShort: payload.product_url_short,
    pixelClicks: payload.pixel_clicks.map((item, index) => {
      if (typeof item === 'string') {
        return {
          id: index,
          value: item,
        }
      }
      return item
    }),
    pixelClicksScripts: payload.pixel_clicks_scripts,
    legalCompliance: {
      erid: {
        media: payload.legal_compliance?.erid.media || null,
        text: payload.legal_compliance?.erid.text || null,
      },
      marker: {
        media: payload.legal_compliance?.marker.media || null,
        text: payload.legal_compliance?.marker.text || null,
      },
    },
  }
}

export const performanceAdSetModelToPayloadAdapter = (model: IPerformanceAdSetModel): IPerformanceAdSetPayloadTo => {
  const result: IPerformanceAdSetPayloadTo = {
    title: model.title,
    description: model.description,
    format: model.format,
    platform: model.platform,
    start: model.start,
    end: model.end,
    erid: model.erid,
    external_id: model.externalId,
    payable_type: model.payType,
    bid_cap: model.bidCap,
    bid_cpa: model.bidCpa,
    impressions: model.impressions,
    budget: model.budget,
    product_url: model.chatbotProductLink,
    mobile_product_url: model.chatbotMobileProductLink,
    chatbot_text: model.chatbotText,
    exclude_streamers: model.excludeStreamers,
    streamers: model.streamers.map(streamer => streamer.id),
    exclude_countries: model.excludeCountries,
    countries: model.countries,
    exclude_countries_auditory: model.excludeCountriesAuditory,
    countries_auditory: model.countriesAuditory,
    exclude_devices_auditory: model.excludeDevicesAuditory,
    devices_auditory: model.devicesAuditory,
    exclude_languages: model.excludeLanguages,
    broadcaster_languages: model.languages,
    exclude_tags: model.excludeTags,
    tags: model.tags,
    visible: model.enabled,
    time_zone: model.timezone,
    target_ctr: model.targetCtr,
    target_cpa: model.targetCpa,
    product_url_short: model.productUrlShort,
    pixel_clicks: model.pixelClicks.map(item => item.value),
    pixel_clicks_scripts: model.pixelClicksScripts,
  }

  // @ts-expect-error: this works
  const unitKey = model?.unit?.key
  if (unitKey) {
    result.unit = unitKey
  }
  return result
}
