<template>
  <div class="group">
    <DashboardSection
      :title="locale.targeting"
      class="group-targeting"
    >
      <GroupTargetingAgencies
        :model="model"
        :disabled="disableFields"
      />
      <!-- <GroupTargetingCPM
        :model="model"
        @input="onInput"
      /> -->
      <div class="group-targeting__separator" />
      <GroupTargetingStreamers
        :model="model"
        @input="onInput"
      />
      <div class="group-targeting__separator" />
      <component
        v-for="(item, i) in fieldsTargeting"
        :key="i"
        :is="item"
        :model="model"
        :disabled="disableFields"
        @input="onInput"
      />
    </DashboardSection>
    <DashboardSection
      :title="locale.targetingAudience"
      class="group-targeting"
    >
      <component
        v-for="(item, i) in fieldsTargetingAudience"
        :key="i"
        :is="item"
        :model="model"
        :disabled="false"
        @input="onInput"
      />
    </DashboardSection>
    <GroupTargetingEvaluation />
  </div>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import GroupTargetingAgencies from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingAgencies.vue'
import GroupTargetingCountries from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingCountries.vue'
import GroupTargetingCountriesAuditory from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingCountriesAuditory.vue'
import GroupTargetingCPM from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingCPM.vue'
import GroupTargetingDevicesAuditory from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingDevicesAuditory.vue'
import GroupTargetingLanguages from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingLanguages.vue'
import GroupTargetingStreamers from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingStreamers.vue'
import GroupTargetingTags from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingTags.vue'

import GroupTargetingAge from './components/GroupTargetingAge'
import GroupTargetingAgeRestrictions from './components/GroupTargetingAgeRestrictions'
import GroupTargetingEvaluation from './components/GroupTargetingEvaluation'
// unrefactored components
import GroupTargetingGender from './components/GroupTargetingGender'

export default {
  components: {
    DashboardSection,
    GroupTargetingAgencies,
    GroupTargetingStreamers,
    GroupTargetingLanguages,
    GroupTargetingCountries,
    GroupTargetingCountriesAuditory,
    GroupTargetingDevicesAuditory,
    GroupTargetingGender,
    GroupTargetingAge,
    GroupTargetingAgeRestrictions,
    GroupTargetingTags,
    GroupTargetingEvaluation,
    GroupTargetingCPM,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  data: () => ({
    fieldsTargeting: [
      GroupTargetingLanguages,
      GroupTargetingCountries,
      GroupTargetingGender,
      GroupTargetingAge,
      GroupTargetingAgeRestrictions,
      GroupTargetingTags,
    ],
    fieldsTargetingAudience: [
      GroupTargetingCountriesAuditory,
      GroupTargetingDevicesAuditory,
    ],
  }),

  computed: {
    locale: ({ $locale }) => ({
      targeting: $locale('targeting.title'),
      targetingAudience: $locale('targetingAudience.title'),
    }),
    disableFields ({ model }) {
      return !!model.streamers.length && !model.excludeStreamers
    },
  },

  created () {
    this.fetchAudience()
  },

  methods: {
    onInput () {
      this.fetchAudience()
    },
    fetchAudience () {
      this.$store.dispatch('group/fetchAudience')
    },
  },
}
</script>

<style lang="scss" scoped>
.group-targeting {
  position: relative;

  &__separator {
    border-top: $--border-base;
    margin-bottom: 20px;
  }
}

.group {
  position: relative;

  ::v-deep {
    .advice {
      position: absolute;
      left: calc(100% + 24px);
      top: 32px;

      @media (max-width: $--tablet-portrait) {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
